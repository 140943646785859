<template>
    <div class="usersBinding">
        <div class="bg"></div>
         <!-- 弹窗绑定姓名 -->
        <div class="popup_bg" v-if="true">
            <div class="popup_content_box">
                <h1 class="title_text">查询我的婚姻结果</h1>
                <div class="input_box">
                    <input type="text" v-model="name_value" placeholder="点此处输入姓名查询">
                </div>
                <div class="button_box animation_scaling" @click="usersBindingbuttom">立即查询</div>
                <p>温馨提示：为保障信息的安全，请输入您预留的姓名，查看结果。</p>
            </div>
        </div>
    </div>
    
</template>
<script>
import { $getResultOrderId } from "../../api/home_marriage_test"
export default {
    data() {
        return{
            name_value:''
        }
    },
    created() {

    },
    methods:{
        getResultOrderIdRequest() {//姓名绑定数据请求
            let data_ = {
                name:this.name_value
            }
            $getResultOrderId(data_).then( ( res ) => {
                console.log('res',res)
                if ( res.status === 200 ) {
                   this.$toast.success('查询成功');
                   location.replace(`/home_marriage_test/payment/result/${res.data.data.order_id}/2?from=${this.$route.query.from}`)
                }
            }).catch ( ( err ) => {
                console.log('err',err)
                this.$toast.fail('姓名填写有误，请重新填写');
            })
        },
    
        usersBindingbuttom () { //姓名绑定按钮
            this.getResultOrderIdRequest()
        }
    }
}
</script>

<style lang="scss" scoped>
.usersBinding{
    .bg{
        -webkit-filter: blur(5px);-moz-filter: blur(5px);-ms-filter: blur(5px);filter: blur(5px);
        width: 7.5rem;
        height: 75.80rem;
        background: url("http://cdn.zhiyileiju.cn/xxs.png");
        background-size: 100% 100%;
    }
    //弹窗绑定手机号
    .popup_bg{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,.5);
        .popup_content_box{
            position: fixed;
            left: 50%;
            top: 50%;
            width: 5rem;
            transform: translate(-50%,-50%);
            padding: .5rem;
            background: #fff;
            border-radius: .3rem;
            .title_text{
                font-weight: 400;
                text-align: center;
                margin-bottom:.2rem ;
                font-size: .5rem;
            }
            .input_box{
                position: relative;
                width: 4rem;
                height: .8rem;
                border: 1px solid #9b1e20;
                margin: auto;
                input{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 4rem;
                    height: .8rem;
                    border: none;
                    font-size: .4rem;
                    text-align: center;
                }
            }
            .button_box{
                margin: auto;
                width: 4rem;
                height: .8rem;
                background: #9b1e20;
                color: #fff;
                font-size: .35rem;
                text-align: center;
                line-height: .8rem;
                border-radius: .8rem;
                margin-top: .5rem;
                margin-bottom: .5rem;
            }
            p{
                color: #525252;
                font-size: .25rem;
                text-align: center;
            }
        }
    }
}
</style>